.resizer {
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	width: 20px;
	background: transparent;
	cursor: ew-resize;
	user-select: none;
	touch-action: none;

	&:hover {
		border-right: 2px solid rgba(100,100,100,0.2);
	}
}

.resizer.isResizing {
	border-right: 2px solid rgba(100,100,100,0.5);
}

.th {
	position: relative;
	user-select: none;

	&:hover {
		cursor: default;
	}
}

.tr {
	display: flex;
	width: fit-content;
	min-width: -webkit-fill-available;
}

.td {
	cursor: default;
	overflow: hidden;
	display: flex;
	align-items: center;
	border: none !important;
}